import React from "react";
import Raffle from "../Components/Admin/Raffle";
import Auctions from "../Components/Admin/Auctions";

export default function Admin() {
  return (
    <div className="lg:py-20 py-16 ">
      <Raffle />
      <Auctions />
    </div>
  );
}
