import Avatar from "./Avatar.json";

export const findUrlByNumber = (number) => {
  const domain = window.location.origin; // Get the current domain

  for (const item of Avatar) {
    // Loop through all keys (like "1of1", "1of12", etc.)
    for (const key in item) {
      // Check if the key exists and is an array
      if (Array.isArray(item[key])) {
        const found = item[key].find((obj) => obj[number]);
        if (found) {
          return found[number]; // Return the URL if found
        }
      }
    }
  }
  return `${domain}/noimage.png`; // Return a default image if not found
};
