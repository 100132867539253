import React from "react";
import Auction from "./Routes/Auction";
import Raffle from "./Routes/Raffle";
import { ToastContainer } from "react-toastify";
import Layout from "./Components/Layout/Layout";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import Home from "./Routes/Home";
import Staking from "./Routes/Staking";
import { useEffect } from "react";
import {  Routes, Route, useLocation, Navigate } from "react-router-dom";
import NProgress from "nprogress";
import { useAccount } from "wagmi";
import Admin from "./Routes/Admin";
import Games from "./Routes/Games";
import useContract  from './hooks/useContract'
const theme = createTheme({
  palette: {
    primary: {
      main: "#9E1D4D",
    },
  },
  typography: {
    fontFamily: "Open Sans, sans-serif",
  },
});

const App = () => {
  const { address } = useAccount();
  const location = useLocation();
  const [owner , setOwner] = React.useState("")
  const {getOwner} = useContract()
  const getTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Home | Fada Staking";
      case "/redemption":
        return "Redemption | Fada Staking";
      case "/staking":
        return "Staking | Fada Staking";
      default:
        return "Fada Staking";
    }
  };
  useEffect(() => {
    const getOwnerAddress = async () => {
      const owner = await getOwner()
      setOwner(owner)
    }
    getOwnerAddress()
    
    const startProgress = () => {
      NProgress.start();
    };

    const endProgress = () => {
      NProgress.done();
    };

    const progressTimeout = setTimeout(() => {
      endProgress();
    }, 500); // Add a timeout to ensure the progress bar is displayed even for quick page loads

    startProgress();

    return () => {
      clearTimeout(progressTimeout);
      endProgress();
    };

    
  }, [location.pathname]);
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <Layout>
        <Routes>
          <Route index element={<>{!address ? <Home /> : <Staking />}</>} />
          <Route path="auction" element={<Auction />} />
          <Route path="raffle" element={<Raffle />} />
          <Route path="admin" element={address && address === owner? <Admin />: <Navigate to={"/"}/>} />
          <Route path="games" element={<Games />} />

        </Routes>
      </Layout>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
        draggable={true}
        position="top-right"
        toastClassName=""
        bodyClassName=""
        progressClassName=""
        pauseOnFocusLoss={true}
        newestOnTop={true}
        theme="colored"
      />
    </ThemeProvider>
  );
};

export default App;
