import { Button, TextField } from "@mui/material";
import React from "react";

export default function AddNewRaffle({
  handleCancel,
  token_id,
  setToken_id,
  start_price,
  setStart_price,
  end_price,
  setEnd_price,
  duration,
  setDuration,
  onSubmitHandler,
}) {
  const ManageTime = (e) => {
    const selectedTimeString = e.target.value + ":00"; // Adding seconds to the datetime string
    const selectedTime = new Date(selectedTimeString).getTime();
    const now = Date.now();
    const remainingSeconds = Math.max(
      0,
      Math.floor((selectedTime - now) / 1000)
    );
    setDuration(remainingSeconds);
  };

  return (
    <form onSubmit={onSubmitHandler} className="flex flex-col gap-4">
      <TextField
        fullWidth
        size="small"
        required
        value={token_id}
        onChange={(e) => setToken_id(e.target.value)}
        className=""
        label="token id"
      />
      <TextField
        fullWidth
        size="small"
        required
        value={start_price}
        onChange={(e) => setStart_price(e.target.value)}
        className=""
        label="Start price"
      />
      <TextField
        fullWidth
        size="small"
        required
        value={end_price}
        onChange={(e) => setEnd_price(e.target.value)}
        className=""
        label="End price"
      />
      <TextField
        fullWidth
        size="small"
        type="datetime-local"
        required
        InputLabelProps={{ shrink: true }}
        // value={duration}
        onChange={(e) => ManageTime(e)}
        className=""
        label="End Time"
      />

      <div className="flex gap-4">
        <Button
          onClick={handleCancel}
          variant="contained"
          className="bg-gray-400"
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" className="bg-primary">
          Submit
        </Button>
      </div>
    </form>
  );
}
