import React, { useState, useEffect } from "react";
import Header from "../Components/Auction/Header";
import Cards from "../Components/Auction/Cards";
import useContract from "../hooks/useContract";
import { useAccount } from "wagmi";
import Loading from "../Components/Loading";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function Auction() {
  const { address } = useAccount();
  const [auctions, setAuctions] = useState([]);
  const [auctions2, setAuctions2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [showFinished, setShowFinished] = useState(false);

  const { getBalance, getTotalAuctions, getAuctions, bid } = useContract();

  useEffect(() => {
    const raffles = async () => {
      setIsLoading(true);
      const totalAuctions = await getTotalAuctions();
      const auctions = await getAuctions(totalAuctions);

      if (address) {
        const balance = await getBalance(address);
        setBalance(balance);
      }
      const sortedAuctions = auctions
        .map((auction, index) => ({
          ...auction,
          originalIndex: index,
        }))
        .sort((a, b) => {
          const endTimeA = parseInt(a.startedAt) + parseInt(a.duration);
          const endTimeB = parseInt(b.startedAt) + parseInt(b.duration);

          return endTimeB - endTimeA;
        });
      setAuctions(sortedAuctions);
      setAuctions2(sortedAuctions);

      setIsLoading(false);
    };
    raffles();
  }, [address]);

  useEffect(() => {
    const filtered = auctions.filter((data) => {
      return (
        searchInput === "" ||
        data[1].toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    const sortedAuctionData = filtered.sort((a, b) => b.end - a.end);
    setAuctions2(sortedAuctionData);
  }, [searchInput]);

  const calculateTimeRemaining = (startedAt, duration) => {
    const now = Date.now() / 1000;
    const endTime = parseInt(startedAt) + parseInt(duration);
    const timeDiff = endTime - now;
    return timeDiff >= 0 ? Math.floor(timeDiff * 1000) : 0;
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="min-h-screen py-24 flex flex-col gap-4  bg-[#F3FCFF] ">
          <div className="container-sk mt-5">
            <Header balance={balance} setSearchInput={setSearchInput} />
            <div className="flex justify-end">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showFinished}
                    onChange={(e) => setShowFinished(e.target.checked)}
                  />
                }
                label="Show finished"
              />
            </div>
            <div className="mt-5 grid    md:grid-cols-3 grid-cols-1 xl:grid-cols-4 lg:gap-10 gap-5">
              {auctions2?.map((auction, index) => {
                const remTime = calculateTimeRemaining(
                  auction?.startedAt,
                  auction?.duration
                );

                if (remTime > 0 || showFinished) {
                  return (
                    <Cards
                      key={index}
                      auction={auction}
                      loading={setIsLoading}
                      bidFunction={bid}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
