import React from "react";
import { RotatingLines } from "react-loader-spinner";
const Loading = () => {
  return (
    <div className="fixed left-0 right-0 top-0 bottom-0 flex items-center justify-center bg-black bg-opacity-60 text-white">
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
      />
    </div>
  );
};

export default Loading;
