import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function AddNewRaffle({
  handleCancel,
  nft_id,
  price,
  tickets,
  end_time,
  limited,
  max_tickets,
  setNft_id,
  setPrice,
  setTickets,
  setEnd_time,
  setLimited,
  setMax_tickets,
  onSubmitHandler,
}) {
  return (
    <form onSubmit={onSubmitHandler} className="flex flex-col gap-4">
      <TextField
        fullWidth
        required
        size="small"
        className=""
        label="NFT Address"
        value={nft_id}
      />

      <TextField
        fullWidth
        required
        type="number"
        size="small"
        className=""
        label="NFT Id"
        value={nft_id}
        onChange={(e) => setNft_id(e.target.value)}
      />
      <TextField
        fullWidth
        required
        type="number"
        size="small"
        className=""
        label="Price"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <TextField
        fullWidth
        type="number"
        size="small"
        className=""
        label="Tickets"
        required
        value={tickets}
        onChange={(e) => setTickets(e.target.value)}
      />
      <TextField
        fullWidth
        required
        type="datetime-local"
        size="small"
        className=""
        label="End time"
        InputLabelProps={{ shrink: true }}
        value={end_time}
        onChange={(e) => setEnd_time(e.target.value)}
      />

      <div className="flex items-center">
        <FormControlLabel
          control={
            <Checkbox
              label=""
              checked={limited}
              onChange={(e) => setLimited(e.target.checked)}
            />
          }
          label="Limited ?"
        />
      </div>

      <TextField
        required
        fullWidth
        type="number"
        size="small"
        className=""
        label="Max ticker per user"
        value={max_tickets}
        onChange={(e) => setMax_tickets(e.target.value)}
      />

      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Payment Type
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="female"
            control={<Radio />}
            label="Fada Points"
          />
          <FormControlLabel value="male" control={<Radio />} label="Matic" />
        </RadioGroup>
      </FormControl>

      <div className="flex gap-4">
        <Button
          onClick={handleCancel}
          variant="contained"
          className="bg-gray-400"
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" className="bg-primary">
          SUBMIT
        </Button>
      </div>
    </form>
  );
}
