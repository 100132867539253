import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { StyledEngineProvider } from "@mui/material/styles";

import "nprogress/nprogress.css";

import "react-toastify/dist/ReactToastify.css";

import App from "./App";

import { WagmiConfig, createConfig } from "wagmi";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { polygon } from "wagmi/chains";
const ALCHEMY_ID = "ab63e207636043c7bae04e1beb6ddd1d";
const WALLETCONNECT_PROJECT_ID = "f6adbe04febb278b8d497cf6a75276de";
const chains = [polygon];

const config = createConfig(
  getDefaultConfig({
    // autoConnect: true,
    // Required API Keys
    //alchemyId: process.env.ALCHEMY_ID, // or infuraId
    //walletConnectProjectId: process.env.WALLETCONNECT_PROJECT_ID,
    alchemyId: ALCHEMY_ID, // or infuraId
    walletConnectProjectId: WALLETCONNECT_PROJECT_ID,
    chains,
    // Required
    appName: "frs",

    // Optional
    // appDescription: "Your App Description",
    // appUrl: "https://family.co", // your app's url
    // appLogo: "https://family.co/logo.png", // your app's logo,no bigger than 1024x1024px (max. 1MB)
  })
);

function Main() {


  // //console.log = function () {};
  // //console.warn = function () {};
  // //console.error = function () {};

  // useEffect(() => {
  //   sessionStorage.setItem("testdata", loggedin);
  // }, [loggedin]);

  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <WagmiConfig config={config}>
          <ConnectKitProvider>
            <App />
          </ConnectKitProvider>
        </WagmiConfig>
      </StyledEngineProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
