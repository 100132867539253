import Web3 from "web3";
import {
  CHAIN_ID,
  NFT_CONTRACT_ADDRESS,
  STAKING_CONTRACT_ADDRESS,
  RAFFLE_CONTRACT_ABI,
  RAFFLE_CONTRACT_ADDRESS,
  NFT_CONTRACT_ABI,
  STACKING_CONTRACT_ABI,
  AUCTION_CONTRACT_ADDRESS,
  AUCTION_CONTRACT_ABI,
} from "../utils/contract";

import { toast } from "react-toastify";

const rpc = "https://polygon-mainnet.infura.io/v3/58d58ac1b05c4298899082ffeb5103cb";

function useContract() {
  const getWeb3 = async (type) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    if (type === "r") {
      const web3 = new Web3(rpc);
      return { web3 };
    } else if (type === "w") {
      const web3 = new Web3(window.ethereum);
      return { web3 };
    }
  };

  const getContract = async (abi, address, type) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    const { web3 } = await getWeb3(type);
    const contract = new web3.eth.Contract(abi, address);
    return contract;
  };

  const getTotalRaffless = async () => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        RAFFLE_CONTRACT_ABI,
        RAFFLE_CONTRACT_ADDRESS,
        "r"
      );
      const totalRaffles = await contract.methods.totalRaffles().call();
      return totalRaffles;
    } catch (error) {
      //console.log(error);
      toast.error("Error in getting total raffles");
    }
  };
  const getRaffles = async (totalRaffleCount) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        RAFFLE_CONTRACT_ABI,
        RAFFLE_CONTRACT_ADDRESS,
        "r"
      );
      // //console.log(contract)
      let raffles = [];
      for (var i = 0; i < totalRaffleCount; i++) {
        const raffle = await contract.methods.raffles(i).call();
        raffles.push(raffle);
      }
      return raffles;
    } catch (error) {
      toast.error("Error in getting total raffles");
    }
  };

  const buyTicket = async (raffleId, quantity) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        RAFFLE_CONTRACT_ABI,
        RAFFLE_CONTRACT_ADDRESS,
        "w"
      );
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      // //console.log(raffleId,quantity);
      const web3 = new Web3(window.ethereum);
      const result = await contract.methods.buyTicket(raffleId, quantity).send({
        from: account,
        // set a custom gas limit here
        gas: 200000,
        gasPrice: await web3.eth.getGasPrice(),

      });
      toast.success("Ticket bought successfully");
      window.location.reload();
      return result;
    } catch (error) {
      toast.error("Error in buying ticket");
    }
  };

  const createRaffle = async (
    nft_id,
    price,
    tickets,
    end_time,
    limited,
    max_tickets
  ) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        RAFFLE_CONTRACT_ABI,
        RAFFLE_CONTRACT_ADDRESS,
        "w"
      );
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      // //console.log(nft_id, price, tickets, end_time, limited, max_tickets);
      const result = await contract.methods
        .createRaffle(nft_id, price, tickets, end_time, limited, max_tickets)
        .send({
          from: account,
        });
      toast.success("Raffle created successfully");
      return result;
    } catch (error) {
      // //console.log(error);
      toast.error("Error in creating raffle");
    }
  };
  // auction functions
  const getTotalAuctions = async () => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        AUCTION_CONTRACT_ABI,
        AUCTION_CONTRACT_ADDRESS,
        "r"
      );
      const totalAuctions = await contract.methods.totalAuctions().call();
      return totalAuctions;
    } catch (error) {
      toast.error("Error in getting total auctions");
    }
  };

  const getAuctions = async (totalAuctionCount) => {
    
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        AUCTION_CONTRACT_ABI,
        AUCTION_CONTRACT_ADDRESS,
        "r"
      );
      // //console.log(contract)
      let auctions = [];
      for (var i = 0; i < totalAuctionCount; i++) {
        const auction = await contract.methods.auctions(i).call();
        auctions.push(auction);
      }
      return auctions;
    } catch (error) {
      toast.error("Error in getting total auctions");
    }
  };

  const getBalance = async (address) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        STACKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS,
        "r"
      );
      let balance = await contract.methods.viewRewards(address).call();
      // convert to ether
      balance = parseInt(Web3.utils.fromWei(balance, "ether"));
      //console.log(balance.toString());
      return balance.toString();
    } catch (error) {
      toast.error("Error in getting balance");
    }
  };
  const bid = async (auctionId, amount) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        AUCTION_CONTRACT_ABI,
        AUCTION_CONTRACT_ADDRESS,
        "w"
      );
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      const result = await contract.methods.bid(auctionId, amount).send({
        from: account,
        value: 0,
      });
      toast.success("Bid placed successfully");
      window.location.reload();

      return result;
    } catch (error) {
      toast.error("Error in placing bid");
    }
  };
  const createAuction = async (token_id, start_price, end_price, duration) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        AUCTION_CONTRACT_ABI,
        AUCTION_CONTRACT_ADDRESS,
        "w"
      );
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      const result = await contract.methods
        .createAuction(token_id, start_price, end_price, duration)
        .send({
          from: account,
        });
      toast.success("Auction created successfully");
      return result;
    } catch (error) {
      toast.error("Error in creating auction");
    }
  };

  const editRaffle = async(id, nft_id,price,tickets,end_time,limited,max_tickets)=>{
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        RAFFLE_CONTRACT_ABI,
        RAFFLE_CONTRACT_ADDRESS,
        "w"
      );
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      const result = await contract.methods
        .editRaffle(id, nft_id,price,tickets,end_time,limited,max_tickets)
        .send({
          from: account,
        });
      toast.success("Raffle edited successfully");
      return result;
    } catch (error) {
      toast.error("Error in editing raffle");
    }
  }

  const drawRaffle = async (raffleId) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        RAFFLE_CONTRACT_ABI,
        RAFFLE_CONTRACT_ADDRESS,
        "w"
      );
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      const result = await contract.methods.draw(raffleId).send({
        from: account,
      });
      toast.success("Raffle drawn successfully");
      return result;
    } catch (error) {
      toast.error("Error in drawing raffle");
    }
  };

  const editAuction = async (auctionId,tokenId, start_price, end_price, duration) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        AUCTION_CONTRACT_ABI,
        AUCTION_CONTRACT_ADDRESS,
        "w"
      );
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      const result = await contract.methods
        .editAuction(auctionId,tokenId, start_price, end_price, duration)
        .send({
          from: account,
        });
      toast.success("Auction edited successfully");
      return result;
    } catch (error) {
      toast.error("Error in editing auction");
    }
  }

  const drawAuction = async (auctionId) => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    try {
      const contract = await getContract(
        AUCTION_CONTRACT_ABI,
        AUCTION_CONTRACT_ADDRESS,
        "w"
      );
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      const result = await contract.methods.draw(auctionId).send({
        from: account,
      });
      toast.success("Auction drawn successfully");
      return result;
    } catch (error) {
      toast.error("Error in drawing auction");
    }
  };

  const getOwner = async () => {
    if (!window.ethereum) {
        toast.error("Please install metamask",{toastId:2});
      return;
    }
    const contract = await getContract(
      RAFFLE_CONTRACT_ABI,
      RAFFLE_CONTRACT_ADDRESS,
      "r"
    );
    const owner = await contract.methods.owner().call();
    // //console.log(owner);
    return owner;
  };
  return {
    getWeb3,
    getContract,
    getTotalRaffless,
    getRaffles,
    buyTicket,
    getBalance,
    getTotalAuctions,
    getAuctions,
    bid,
    createRaffle,
    createAuction,
    editRaffle,
    drawRaffle,
    editAuction,
    drawAuction,
    getOwner,
  };
}

export default useContract;
