import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Modal from "@mui/material/Modal";
import AddNewRaffle from "../Modal/AddNewRaffle";
import useContract from "../../hooks/useContract";
import Loading from "../Loading";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import Web3 from "web3";
import Countdown from "react-countdown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { findUrlByNumber } from "../../utils/findUrlByNumber.js";

export default function Raffle() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [raffles, setRaffles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // edit raffle
  const [raffleId_edit, setRaffleId_edit] = useState("");
  const [nft_id_edit, setNft_id_edit] = useState("");
  const [price_edit, setPrice_edit] = useState("");
  const [tickets_edit, setTickets_edit] = useState("");
  const [end_time_edit, setEnd_time_edit] = useState("");
  const [limited_edit, setLimited_edit] = useState(false);
  const [max_tickets_edit, setMax_tickets_edit] = useState("");

  const [editOpen, setEditOpen] = React.useState(false);
  const handleEditOpen = (raffle, index) => {
    setRaffleId_edit(index);
    setNft_id_edit(raffle.nftId);
    setPrice_edit(raffle.price);
    setTickets_edit(raffle.tickets);
    // covert raffle end to date time from unix timestamp
    setEnd_time_edit(raffle.end);
    setLimited_edit(raffle.isLimited);
    setMax_tickets_edit(raffle.maxTicketsPerUser);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };
  const onEditSubmitHandler = async (e) => {
    e.preventDefault();
    setEditOpen(false);
    setIsLoading(true);
    if (!address) {
      toast.error("Please connect your wallet first", { toastId: 1 });
    }
    const unix_endTime_edit = new Date(end_time_edit).getTime() / 1000;
    if (unix_endTime_edit < Date.now() / 1000) {
      toast.error("Please select a future date");
      return;
    }
    await editRaffle(
      raffleId_edit,
      nft_id_edit,
      Web3.utils.toWei(price_edit.toString(), "ether"),
      tickets_edit,
      unix_endTime_edit,
      limited_edit,
      max_tickets_edit
    );

    setIsLoading(false);
    handleEditClose(); // //console.log("edit submit");
    // //console.log( nft_id_edit, price_edit, tickets_edit, end_time_edit, limited_edit, max_tickets_edit);
  };

  const drawRaffleHandler = async (raffleId) => {
    if (!window.ethereum) {
      toast.error("Please install metamask", { toastId: 2 });
      return;
    }

    setIsLoading(true);
    await drawRaffle(raffleId);
    setIsLoading(false);
  };

  const { address } = useAccount();

  // create new raffle states
  const [nft_id, setNft_id] = useState("");
  const [price, setPrice] = useState("");
  const [tickets, setTickets] = useState("");
  const [end_time, setEnd_time] = useState("");
  const [limited, setLimited] = useState(false);
  const [max_tickets, setMax_tickets] = useState("");
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!address) {
      toast.error("Please connect your wallet first", { toastId: 1 });
      return;
    }
    setOpen(false);
    setIsLoading(true);
    // //console.log("submit");
    // change the end time to unix timestamp
    const end_time_unix = new Date(end_time).getTime() / 1000;
    // //console.log(end_time_unix);
    if (end_time_unix < Date.now() / 1000) {
      toast.error("Please select a future date");
      return;
    }

    await createRaffle(
      nft_id,
      Web3.utils.toWei(price.toString(), "ether"),
      tickets,
      end_time_unix,
      limited,
      max_tickets
    );
    setIsLoading(false);
    handleClose();

    // //console.log(nft_id, price, tickets, end_time_unix, limited, max_tickets);
  };
  const { getTotalRaffless, getRaffles, createRaffle, editRaffle, drawRaffle } =
    useContract();

  useEffect(() => {
    const fetchRaffles = async () => {
      setIsLoading(true);
      const totalRaffles = await getTotalRaffless();
      const raffles = await getRaffles(totalRaffles);
      setRaffles(raffles);
      setIsLoading(false);
    };
    fetchRaffles();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="mt-5 container-sk">
          <Accordion className="bg-gray-100">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="font-bold text-lg md:text-xl lg:text-2xl text-primary">
                Raffles:
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-center">
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  className="bg-primary flex gap-2 items-center"
                >
                  <AddCircleIcon />
                  <span> Add New</span>
                </Button>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-3">
                {raffles.map((raffle, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-5  rounded-lg border border-gray-200/20 bg-white p-5 hover:shadow-lg shadow-md duration-300 flex items-center justify-between"
                    >
                      <GetImage raffle={raffle} />

                      <div className="flex flex-col gap-4 lg:w-40">
                        <Button
                          onClick={() => handleEditOpen(raffle, index)}
                          variant="contained"
                          className="bg-primary mr-4 w-full"
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => drawRaffleHandler(index)}
                          variant="contained"
                          className="bg-primary w-full"
                        >
                          Draw
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!(raffles?.length > 0) && (
                <p className="text-primary pb-20 pt-10 font-bold text-lg text-center">
                  {" "}
                  No Raffle Found
                </p>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="bg-white rounded-lg w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[90vh] overflow-auto lg:max-w-[60vw] xl:max-w-[50vw] md:max-w-[80vw] max-w-[90vh] ">
          <div className="flex justify-between gap-4 items-center flex-wrap py-3 px-5 lg:px-10 bg-primary text-white">
            <h4 className="lg:text-xl md:text-lg text-base font-bold">
              New Raffle
            </h4>
            <div className="flex justify-between gap-4 items-center">
              <Button
                variant="outlined"
                className="border-white hover:border-white shadow text-base text-white"
              >
                x5414512
              </Button>
              <Button
                variant="outlined"
                className="border-white hover:border-white shadow text-base text-white"
              >
                {" "}
                x5414512
              </Button>
            </div>
          </div>

          <div className="p-5 lg:p-10">
            <AddNewRaffle
              handleCancel={handleClose}
              nft_id={nft_id}
              setNft_id={setNft_id}
              price={price}
              setPrice={setPrice}
              tickets={tickets}
              setTickets={setTickets}
              end_time={end_time}
              setEnd_time={setEnd_time}
              limited={limited}
              setLimited={setLimited}
              max_tickets={max_tickets}
              setMax_tickets={setMax_tickets}
              onSubmitHandler={onSubmitHandler}
            />
          </div>
        </div>
      </Modal>

      {/* edit modal */}
      <Modal
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="bg-white rounded-lg w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[90vh] overflow-auto lg:max-w-[60vw] xl:max-w-[50vw] md:max-w-[80vw] max-w-[90vh] ">
          <div className="flex justify-between gap-4 items-center flex-wrap py-3 px-5 lg:px-10 bg-primary text-white">
            <h4 className="lg:text-xl md:text-lg text-base font-bold">
              update Raffle
            </h4>
          </div>

          <div className="p-5 lg:p-10">
            <AddNewRaffle
              handleCancel={handleEditClose}
              nft_id={nft_id_edit}
              setNft_id={setNft_id_edit}
              price={price_edit}
              setPrice={setPrice_edit}
              tickets={tickets_edit}
              setTickets={setTickets_edit}
              end_time={end_time_edit}
              setEnd_time={setEnd_time_edit}
              limited={limited_edit}
              setLimited={setLimited_edit}
              max_tickets={max_tickets_edit}
              setMax_tickets={setMax_tickets_edit}
              onSubmitHandler={onEditSubmitHandler}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export const GetImage = ({ raffle }) => {
  //console.log("Sulaiman", raffle);
  const [nft_image, setNft_image] = useState(null);

  useEffect(() => {
    const getPanelId = async () => {
      try {
        // Fetch the image URL using your function that gets the URL based on the raffle
        const imageUrl = findUrlByNumber(raffle[0]);
  
        // Construct the absolute URL for your API proxy
        const absoluteUrl = `https://gmgallery.blockchainbunch.com/api/image-proxy?imageUrl=${encodeURIComponent(imageUrl)}`;
  
        // Fetch the image using the absolute URL
        const res = await fetch(absoluteUrl);
  
        // Check if the response is OK
        if (!res.ok) {
          throw new Error("Something went wrong");
        }
  
        // Convert the response to a blob (image data)
        const imageBlob = await res.blob();
  
        // Create an object URL for the image from the blob
        const imageObjectUrl = URL.createObjectURL(imageBlob);
  
        // Set the image URL to state if the blob is valid
        if (imageBlob) {
          setNft_image(imageObjectUrl); // Use imageObjectUrl here to display the image correctly
        }
      } catch (err) {
        console.error(err); // Use console.error to log the error
      }
    };
  
    getPanelId();
  }, []); // Ensure to include raffle in the dependency array to re-fetch when it changes
  

  const raffleEndTimeUnix = raffle?.end;

  const calculateTimeRemaining = () => {
    if (raffleEndTimeUnix) {
      const now = Date.now() / 1000;
      const timeDiff = raffleEndTimeUnix - now;
      return timeDiff >= 0 ? Math.floor(timeDiff * 1000) : 0;
    }
    return 0;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    if (!raffleEndTimeUnix) return;
    const remainingTime = calculateTimeRemaining();
    setTimeRemaining(remainingTime);
    //console.log("Remaining time:", remainingTime, "milliseconds");
  }, [raffleEndTimeUnix]);

  const priceEach = (raffle?.price / 1000000000000000000).toFixed(0);

  return (
    <div className="flex gap-4 items-center">
      <img
        placeholder="blur"
        src={nft_image || "/noimage.png"}
        width={500}
        height={500}
        alt="test1"
        className="object-contain h-20 w-fit"
      />
      <div>
        <p className="text-sm font-semibold">NFT ID: {raffle.nftId}</p>
        <p className=" text-xs md:text-sm ">P/T : {priceEach} </p>
        <p className="text-sm font-semibold">
          Sold: {raffle.ticketsSold}/{raffle.tickets}
        </p>
        <Countdown
          date={Date.now() + timeRemaining}
          className="text-sm font-bold text-primary"
        >
          <span className="text-sm text-red-700 font-bold">Finished</span>
        </Countdown>
      </div>
    </div>
  );
};
