import React, { useState } from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { useEffect } from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import Countdown from "react-countdown";
import bigInt from "big-integer";
import { findUrlByNumber } from "../../utils/findUrlByNumber.js";

export default function Cards({ auction, loading, bidFunction }) {
  const { address } = useAccount();
  const [bid, setBid] = useState(0);
  const [bid2, setBid2] = useState(0);
  const [nft_image, setNft_image] = useState(null);
  const [categoryname, setCategoryName] = useState("");

  const bidChangeHandler = (e) => {
    const inputValue = e.target.value;

    const inputValue2 = bigInt(inputValue).multiply("1000000000000000000");

    setBid(inputValue);
    setBid2(inputValue2.toString());
  };

  useEffect(() => {
    const getPanelId = async () => {
      try {
        // Fetch the image URL from your function that gets the URL based on tokenId
        const imageUrl = findUrlByNumber(auction.tokenId);
        
        // Construct the absolute URL for your API proxy
        const absoluteUrl = `https://gmgallery.blockchainbunch.com/api/image-proxy?imageUrl=${encodeURIComponent(imageUrl)}`;
  
        // Use the absolute URL to fetch the image
        const res = await fetch(absoluteUrl);
  
        if (!res.ok) {
          throw new Error("Something went wrong");
        }
  
        // Convert the response to a blob (image data)
        const imageBlob = await res.blob();
  
        // Create an object URL for the image from the blob
        const imageObjectUrl = URL.createObjectURL(imageBlob);
  
        // Set the image URL to state
        setNft_image(imageObjectUrl);
  
        // Check if the image URL is "/noimage.png" (as per your logic)
        setCategoryName(
          imageUrl.includes("/noimage.png") ? "System" : "Fayden"
        );
      } catch (err) {
        // Handle error
        console.error(err);
      }
    };
  
    getPanelId();
  }, [auction.tokenId]); // Re-fetch when tokenId changes
  
  const bidHandler = async () => {
    loading(true);
    if (!address) {
      loading(false);
      toast.error("Please connect your wallet", { toastId: 1 });
      return;
    }
    let minimum_bid;
    if (auction.highestBid == 0) {
      minimum_bid = auction.startingPrice;
    } else {
      minimum_bid = auction.highestBid;
    }

    if (bid <= Math.round(minimum_bid / 1000000000000000000)) {
      loading(false);
      toast.error("Bid must be higher than highest bid");
      return;
    }

    if (
      Number(auction.startedAt) + Number(auction.duration) <
      Date.now() / 1000
    ) {
      loading(false);
      toast.error("Auction has ended");
      return;
    }

    await bidFunction(auction?.originalIndex, bid2);
    loading(false);
  };

  const priceEnd = (auction?.endingPrice / 1000000000000000000).toFixed(0);
  const HigestBid = (auction?.highestBid / 1000000000000000000).toFixed(0);
  const StartingBid = (auction?.startingPrice / 1000000000000000000).toFixed(0);

  const calculateTimeRemaining = () => {
    const now = Math.floor(Date.now() / 1000);
    const startedAt = parseInt(auction.startedAt);
    const duration = parseInt(auction.duration);

    if (startedAt && duration) {
      const endTime = startedAt + duration;
      const timeDiff = endTime - now;
      return timeDiff >= 0 ? timeDiff * 1000 : 0;
    }
    return 0;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const remainingTime = calculateTimeRemaining();
    setTimeRemaining(remainingTime);

    const interval = setInterval(() => {
      const remainingTime = calculateTimeRemaining();
      setTimeRemaining(remainingTime);
    }, 1000); // Update the remaining time every second

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Now you can use the 'timeRemaining' variable in your component to display the remaining time

  return (
    <div className="border  border-gray-200 shadow-lg hover:shadow-xl duration-300 rounded-xl overflow-clip">
      <p className="bg-gray-700 text-white lg:text-lg px-4 py-2 text-center">
        {categoryname} #{auction.tokenId}
      </p>
      <div className="relative">
        <img
          placeholder="blur"
          src={nft_image}
          width={500}
          height={500}
          alt="test1"
          className="object-cover aspect-square"
        />
        {timeRemaining > 0 && (
          <div className="absolute top-0 left-0 m-2 flex gap-1 items-center bg-white rounded px-2 py-1">
            <div className="h-3 w-3 rounded-full bg-green-700   animate-pulse"></div>
            <div className="text-green-700 font-bold text-xs">Online</div>
          </div>
        )}
        <div className="absolute top-0 right-0    px-2 py-1 m-0  backdrop-blur-lg font-bold text-primary  text-sm">
          <Countdown date={Date.now() + timeRemaining}>
            <span>Finished</span>
          </Countdown>
        </div>
      </div>

      <p className="text-sm font-bold text-center">
        Auction ID: {auction?.originalIndex}
      </p>
      <div className="flex gap-x-4 gap-y-1 flex-col items-center py-2 text-sm w-full">
        <span className=" text-center font-semibold w-fit">
          <EmojiEventsIcon titleAccess="Highest Bid" /> Hightest Bid:
          {HigestBid > 0 ? HigestBid : StartingBid}
        </span>
      </div>

      <div className="shadow-lg grid grid-cols-2 gap-4 border-t border-gray-500/10">
        <form className="">
          <input
            type="number"
            onChange={bidChangeHandler}
            value={bid !== 0 && bid}
            className="h-full  text-center focus:border-none focus:outline-none"
            placeholder="Your Bid"
          />
        </form>
        <Button
          onClick={bidHandler}
          className="bg-gray-600 hover:bg-gray-800 text-white lg:text-lg  py-2"
        >
          Bid
        </Button>
      </div>
    </div>
  );
}
