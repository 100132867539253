import React from "react";
import Header from "./Header";

export default function Layout({ children }) {
  return (
    <>
      <header className="fixed top-0 z-40 w-full bg-transparent">
        <Header />
      </header>

      <main>{children}</main>
    </>
  );
}
