import React, { useEffect, useState } from "react";
import Header from "../Components/Raffle/Header";
import Cards from "../Components/Raffle/Cards";
import useContract from "../hooks/useContract";
import { useAccount } from "wagmi";
import Loading from "../Components/Loading";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function Auction() {
  const { address } = useAccount();
  const [raffles, setRaffles] = useState([]);
  const [raffles2, setRaffles2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const { getTotalRaffless, getRaffles, buyTicket, getBalance } = useContract();
  const [showFinished, setShowFinished] = useState(false);
  const [searchInput, setSearchInput] = useState(""); // State for search input

  //console.log(searchInput);

  useEffect(() => {
    const raffles = async () => {
      setIsLoading(true);
      const totalRaffles = await getTotalRaffless();
      const raffles = await getRaffles(totalRaffles);
      if (address) {
        const balance = await getBalance(address);
        setBalance(balance);
      }

      console.log("Raffles",raffles)
      const filteredRaffles = raffles
      .filter((item) => {
        const itemIndex0 = item["0"];
        return (
          itemIndex0 !== "3333" &&
          itemIndex0 !== "998899" &&
          itemIndex0 !== "233" &&
          itemIndex0 !== "112" &&
          itemIndex0 !== "112233" 
        );
      })
      .map((item, index) => {
        return { ...item, index: index + 6 };
      });
      const sortedRaffles = filteredRaffles.sort((a, b) => b.end - a.end);
     
      setRaffles(sortedRaffles);
      setRaffles2(sortedRaffles);
      setIsLoading(false);
    };
    raffles();
  }, [address]);

  //console.log(raffles);
  const calculateTimeRemaining = (raffleEndTimeUnix) => {
    const now = Date.now() / 1000;
    const timeDiff = raffleEndTimeUnix - now;
    return timeDiff >= 0 ? Math.floor(timeDiff * 1000) : 0;
  };

  useEffect(() => {
    
      const filtered = raffles2.filter((data) => {
        return (
          searchInput === "" ||
          data[0].toLowerCase().includes(searchInput.toLowerCase())
        );
      });

      const sortedFilteredRaffles = filtered.sort((a, b) => b.end - a.end);

      setRaffles(sortedFilteredRaffles);
   
  }, [searchInput]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="min-h-screen py-24 flex flex-col gap-4  bg-[#F3FCFF] ">
          <div className="container-sk mt-5">
            <Header balance={balance} setSearchInput={setSearchInput} />
            <div className="flex justify-end">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showFinished}
                    onChange={(e) => setShowFinished(e.target.checked)}
                  />
                }
                label="Show finished"
              />
            </div>

            <div className="mt-5 grid grid-cols-1  md:grid-cols-3 xl:grid-cols-4 lg:gap-10 gap-5">
              {raffles?.map((raffle, index) => {
                const remTime = calculateTimeRemaining(raffle?.end);

                if (remTime > 0 || showFinished) {
                  return (
                    <Cards
                      key={index}
                      raffle={raffle}
                      buyTicket={buyTicket}
                      loading={setIsLoading}
                    />
                  );
                } else {
                  return null;
                }
              })}

              {raffles?.length < 1 && (
                <p className="text-center font-bold w-full">
                  No Active Raffle Found
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
