import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import Countdown from "react-countdown";
import { findUrlByNumber } from "../../utils/findUrlByNumber.js";

export default function Cards({ raffle, buyTicket, loading }) {
  const [ticketQuantity, setTicketQuantity] = useState(1);
  const { address } = useAccount();
  const [nft_image, setNft_image] = useState(null);
  const [categoryname, setCategoryName] = useState("");
  const ticketQuantityChangeHandler = (e) => {
    setTicketQuantity(e.target.value);
  };
  const buyTicketHandler = async () => {
    loading(true);
    if (!address) {
      loading(false);
      toast.error("Please connect your wallet", { toastId: 1 });
      return;
    }
    if (Number(raffle.end) < Date.now() / 1000) {
      loading(false);
      toast.error("Raffle has ended");
      return;
    }
    await buyTicket(raffle.index, ticketQuantity);
    loading(false);
  };

  useEffect(() => {
    const getPanelId = async () => {
      try {
        // Fetch the image URL using your function that gets the URL based on the raffle
        const imageUrl = findUrlByNumber(raffle?.[0]);
  
        // Construct the absolute URL for your API proxy
        const absoluteUrl = `https://gmgallery.blockchainbunch.com/api/image-proxy?imageUrl=${encodeURIComponent(imageUrl)}`;
  
        // Fetch the image directly from the absolute URL
        const res = await fetch(absoluteUrl);
        
        if (!res.ok) {
          throw new Error("Something went wrong");
        }
  
        // Convert the response to a blob (image data)
        const imageBlob = await res.blob();
  
        // Create an object URL for the image from the blob
        const imageObjectUrl = URL.createObjectURL(imageBlob);
  
        // Set the image URL to state
        setNft_image(imageObjectUrl);
  
        // Check if the image URL is "/noimage.png" (as per your logic)
        setCategoryName(
          imageUrl.includes("/noimage.png") ? "System" : "Fayden"
        );
      } catch (err) {
        // Handle error
        console.error(err);
      }
    };
  
    getPanelId();
  }, []); 
  

  const raffleEndTimeUnix = raffle?.end;

  const calculateTimeRemaining = () => {
    if (raffleEndTimeUnix) {
      const now = Date.now() / 1000;
      const timeDiff = raffleEndTimeUnix - now;
      return timeDiff >= 0 ? Math.floor(timeDiff * 1000) : 0;
    }
    return 0;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    if (!raffleEndTimeUnix) return;
    const remainingTime = calculateTimeRemaining();
    setTimeRemaining(remainingTime);
    //console.log("Remaining time:", remainingTime, "milliseconds");
  }, [raffleEndTimeUnix]);

  const priceEach = Math.round(raffle?.price / 1000000000000000000);

  //console.log("raffles spidy", raffle);

  return (
    <div className="relative ">
      <div className="border border-primary/10 rounded-xl overflow-clip ">
        <p className="bg-gray-700 text-white lg:text-lg px-4 py-2 text-center">
          {categoryname} #{raffle.nftId}
        </p>

        <div className="relative">
          <img
            placeholder="blur"
            src={nft_image || "/noimage.png"}
            width={500}
            height={500}
            alt="test1"
            className="object-contain w-full"
          />
          {timeRemaining > 0 && (
            <div className="absolute bottom-0 left-0 m-2 flex gap-1 items-center bg-white rounded px-2 py-1">
              <div className="h-3 w-3 rounded-full bg-green-700   animate-pulse"></div>
              <div className="text-green-700 font-bold text-xs">Online</div>
            </div>
          )}

          <div className="absolute bottom-0 left-0 p-3 ">
            <p className="text-xs font-bold text-white">Owned: 4</p>
            <p className="text-xs font-bold text-white">
              Winnder: 123123213123
            </p>
          </div>

          <span className="px-3 py-1 text-center font-semibold absolute top-0 left-0 m-0  text-primary text-sm  backdrop-blur-lg ">
            {raffle.ticketsSold}/{raffle.tickets}
          </span>
          <div className="absolute top-0 right-0    px-2 py-1 m-0  backdrop-blur-lg font-bold text-primary  text-sm">
            <Countdown date={Date.now() + timeRemaining}>
              <span>Finished</span>
            </Countdown>
          </div>
        </div>

        <div className="font-bold text-center bg-white py-2">
          <p>{priceEach} pts/ticket</p>
        </div>

        <div className="border-t flex ">
          <form className="w-full">
            <input
              type="number"
              onChange={ticketQuantityChangeHandler}
              value={ticketQuantity !== 0 && ticketQuantity}
              className="h-full px-2  text-center focus:border-none w-full focus:outline-none"
              placeholder="Ticket quantity "
            />
          </form>
          <Button
            variant="contained"
            onClick={buyTicketHandler}
            className="bg-gray-700  hover:bg-gray-900 rounded-none w-40 text-sm capitalize text-white lg:text-lg py-2"
          >
            Buy&nbsp;Tickets
          </Button>
        </div>
      </div>
      {raffle?.isLimied && (
        <p className="bg-primary/90 absolute top-0 rounded-tr-lg right-0 text-white  px-2 text-xs text-center flex justify-center">
          {raffle?.maxTicketsPerUser} Limited
        </p>
      )}
    </div>
  );
}
