import React from "react";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Games() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [currentGame, setCurrentGame] = React.useState(null);

  const selectGame = async (url) => {
    setCurrentGame(url);
    handleOpen();
  };

  const data = [
    {
      src: "https://html5.gamedistribution.com/6235b12e85584c0298687b0856aaf657/",
      image:
        "https://img.gamepix.com/games/plinko/cover/plinko.png?width=600&height=340&fit=cover&quality=90",
      name: "Plinko",
    },
    {
      src: "https://play.famobi.com/wrapper/om-nom-run/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/OmNomRunTeaser.jpg?v=0.2-f148b671",
      name: "OM NOM RUN",
    },
    {
      src: "https://play.famobi.com/wrapper/garden-bloom/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/GardenBloomTeaser.jpg?v=0.2-f148b671",
      name: "GARDEN BLOOM",
    },
    {
      src: "https://play.famobi.com/wrapper/bubble-tower-3d/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/BubbleTower3dTeaser.jpg?v=0.2-f148b671",
      name: "BUBBLE TOWER 3D",
    },
    {
      src: "https://play.famobi.com/cannon-balls-3d/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/CannonBalls3dTeaser.jpg?v=0.2-f148b671",
      name: "CANNON BALLS 3D",
    },
    {
      src: "https://play.famobi.com/wrapper/tower-crash-3d/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/TowerCrash3dTeaser.jpg?v=0.2-f148b671",
      name: "TOWER CRASH 3D",
    },
    {
      src: "https://play.famobi.com/wrapper/element-blocks/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/ElementBlocksTeaser.jpg?v=0.2-f148b671",
      name: "ELEMENT BLOCKS",
    },

    {
      src: "https://play.famobi.com/wrapper/zoo-boom/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/ZooBoomTeaser.jpg?v=0.2-f148b671",
      name: "ZOO BOOM",
    },
    {
      src: "https://play.famobi.com/wrapper/moto-x3m-pool-party/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/MotoX3mPoolPartyTeaser.jpg?v=0.2-f148b671",
      name: "MOTO X3M POOL PARTY",
    },
    {
      src: "https://play.famobi.com/wrapper/bubble-woods/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/BubbleWoodsTeaser.jpg?v=0.2-f148b671",
      name: "BUBBLE WOODS",
    },
    {
      src: "https://play.famobi.com/wrapper/3d-free-kick/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/3dFreeKickTeaser.jpg?v=0.2-f148b671",
      name: "3D FREE KICK",
    },
    {
      src: "https://play.famobi.com/wrapper/8-ball-billiards-classic/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/8BallBilliardsClassicTeaser.jpg?v=0.2-f148b671",
      name: "8 BALL BILLIARDS CLASSIC",
    },
    {
      src: "https://play.famobi.com/wrapper/archery-world-tour/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/ArcheryWorldTourTeaser.jpg?v=0.2-f148b671",
      name: "ARCHERY WORLD TOUR",
    },
    {
      src: "https://play.famobi.com/wrapper/totemia-cursed-marbles/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/TotemiaCursedMarblesTeaser.jpg?v=0.2-f148b671",
      name: "TOTEMIA: CURSED MARBLES",
    },
    {
      src: "https://play.famobi.com/wrapper/table-tennis-world-tour/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/TableTennis_WorldTour_Teaser.jpg?v=0.2-f148b671",
      name: "TABLE TENNIS WORLD TOUR",
    },
    {
      src: "https://play.famobi.com/wrapper/solitaire-klondike/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/SolitaireKlondikeTeaser.jpg?v=0.2-f148b671",
      name: "SOLITAIRE KLONDIKE",
    },
    {
      src: "https://play.famobi.com/wrapper/onet-connect-classic/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/OnetConnectClassicTeaser.jpg?v=0.2-f148b671",
      name: "ONET CONNECT CLASSIC",
    },
    {
      src: "https://play.famobi.com/wrapper/diamond-rush/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/DiamondRushTeaser.jpg?v=0.2-f148b671",
      name: "DIAMOND RUSH",
    },
    {
      src: "https://play.famobi.com/wrapper/truck-trials/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/TruckTrialsTeaser.jpg?v=0.2-f148b671",
      name: "TRUCK TRIALS",
    },
    {
      src: "https://play.famobi.com/wrapper/endless-truck/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/EndlessTruckTeaser.jpg?v=0.2-f148b671",
      name: "ENDLESS TRUCK",
    },
    {
      src: "https://play.famobi.com/wrapper/candy-bubble/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/CandyBubble_Teaser.jpg?v=0.2-f148b671",
      name: "CANDY BUBBLE",
    },
    {
      src: "https://play.famobi.com/wrapper/gold-mine/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/GoldMineTeaser.jpg?v=0.2-f148b671",
      name: "GOLD MINE",
    },
    {
      src: "https://play.famobi.com/wrapper/protect-the-planet/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/ProtectThePlanetTeaser.jpg?v=0.2-f148b671",
      name: "PROTECT THE PLANET",
    },
    {
      src: "https://play.famobi.com/wrapper/dragons-trail/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/DragonsTrailTeaser.jpg?v=0.2-f148b671",
      name: "DRAGONS TRAIL",
    },
    {
      src: "https://play.famobi.com/wrapper/tiki-taka-run/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/TikiTakaRunTeaser.jpg?v=0.2-f148b671",
      name: "TIKI TAKA RUN",
    },
    {
      src: "https://play.famobi.com/wrapper/soccertastic/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/Soccertastic_Teaser.jpg?v=0.2-f148b671",
      name: "SOCCERTASTIC",
    },
    {
      src: "https://play.famobi.com/wrapper/orange-bubbles/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/OrangeBubblesTeaser.jpg?v=0.2-f148b671",
      name: "ORANGE BUBBLES",
    },
    {
      src: "https://play.famobi.com/wrapper/pizza-ninja-3/A1000-10",
      image:
        "https://img.cdn.famobi.com/portal/html5games/images/tmp/PizzaNinja3Teaser.jpg?v=0.2-f148b671",
      name: "PIZZA NINJA 3",
    },
  ];

  return (
    <div className="py-20 container-sk">
      <h1 className="lg:text-4xl md:text-2xl font-bold text-center my-10 text-primary">
        Welcome to Fada Games
      </h1>

      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 lg:gap-8">
        {data.map((item, index) => (
          <div key={index} className="flex flex-col items-center gap-2">
            <img
              src={item?.image}
              onClick={() => selectGame(item?.src)}
              alt={item?.name}
              className="cursor-pointer hover:scale-105 duration-300 w-full object-cover aspect-square"
            />

            <span className="text-primary font-extrabold">{item?.name}</span>
          </div>
        ))}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div className="absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <iframe
              src={currentGame}
              className="w-screen h-screen "
              scrolling="none"
              frameBorder="0"
            ></iframe>
          </div>

          <IconButton
            onClick={() => {
              handleClose();
            }}
            className="fixed z-50 top-0 right-0 text-white bg-white/50 lg:m-6 m-2"
          >
            <CloseIcon />
          </IconButton>
        </>
      </Modal>
    </div>
  );
}
