import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Modal from "@mui/material/Modal";
import AddNewAuction from "../Modal/AddNewAuction";
import useContract from "../../hooks/useContract";
import Loading from "../Loading";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import Web3 from "web3";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Countdown from "react-countdown";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { findUrlByNumber } from "../../utils/findUrlByNumber.js";

export default function Auctions() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [auctions, setAuctions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // edit states
  const [token_id_edit, setToken_id_edit] = useState("");
  const [start_price_edit, setStart_price_edit] = useState("");
  const [end_price_edit, setEnd_price_edit] = useState("");
  const [duration_edit, setDuration_edit] = useState("");
  const [auctionId_edit, setAuctionId_edit] = useState("");

  // edit  modals
  const [editOpen, setEditOpen] = React.useState(false);
  const handleEditOpen = (auction, index) => {
    setAuctionId_edit(index);
    setToken_id_edit(auction.tokenId);
    setStart_price_edit(auction.startingPrice);
    setEnd_price_edit(auction.endingPrice);
    setDuration_edit(auction.duration);

    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);
  const onEditSubmitHandler = async (e) => {
    e.preventDefault();
    // //console.log("edit submit");
    // //console.log(token_id_edit, start_price_edit, end_price_edit, duration_edit);
    setEditOpen(false);
    setIsLoading(true);
    if (!address) {
      toast.error("Please connect your wallet first", { toastId: 1 });
    }
    await editAuction(
      auctionId_edit,
      token_id_edit,
      Web3.utils.toWei(start_price_edit.toString(), "ether"),
      Web3.utils.toWei(end_price_edit.toString(), "ether"),
      duration_edit
    );
    setIsLoading(false);
    handleEditClose();
  };

  const onDraWHanlder = async (id) => {
    // //console.log("draw submit");
    // //console.log(token_id_edit, start_price_edit, end_price_edit, duration_edit);
    setEditOpen(false);
    setIsLoading(true);
    if (!address) {
      toast.error("Please connect your wallet first", { toastId: 1 });
    }
    await drawAuction(id);
    setIsLoading(false);
    handleEditClose();
  };

  const { address } = useAccount();
  // create new auction
  const [token_id, setToken_id] = useState("");
  const [start_price, setStart_price] = useState("");
  const [end_price, setEnd_price] = useState("");
  const [duration, setDuration] = useState("");
  const {
    getTotalAuctions,
    getAuctions,
    createAuction,
    editAuction,
    drawAuction,
  } = useContract();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!address) {
      toast.error("Please connect your wallet first", { toastId: 1 });
      return;
    }
    setOpen(false);
    setIsLoading(true);
    // //console.log(token_id, start_price, end_price, duration);

    await createAuction(
      token_id,
      Web3.utils.toWei(start_price.toString(), "ether"),
      Web3.utils.toWei(end_price.toString(), "ether"),
      duration
    );
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchAuctions = async () => {
      setIsLoading(true);
      const totalAuctions = await getTotalAuctions();
      const auctions = await getAuctions(totalAuctions);
      setAuctions(auctions);
      setIsLoading(false);
    };
    fetchAuctions();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="mt-5 container-sk">
          <Accordion className="bg-gray-100">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="font-bold text-lg md:text-xl lg:text-2xl text-primary">
                Auctions:
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex justify-center">
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  className="bg-primary flex gap-2 items-center"
                >
                  <AddCircleIcon />
                  <span> Add New</span>
                </Button>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-3">
                {auctions?.length > 0 &&
                  auctions.map((auction, index) => {
                    return (
                      <div
                        key={index}
                        className="mt-5  rounded-lg border border-gray-200/20 p-5 bg-white hover:shadow-lg shadow-md duration-300 flex justify-between items-center"
                      >
                        <GetImage auction={auction} />
                        <div className="flex flex-col gap-3">
                          <Button
                            onClick={() => handleEditOpen(auction, index)}
                            variant="contained"
                            className="bg-primary w-full mr-3"
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => onDraWHanlder(index)}
                            variant="contained"
                            className="bg-primary w-full"
                          >
                            Draw
                          </Button>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {!(auctions?.length > 0) && (
                <p className="text-primary pb-20 pt-10 font-bold text-lg text-center">
                  {" "}
                  No Auction Found
                </p>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="bg-white rounded-lg w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[90vh] overflow-auto lg:max-w-[60vw] xl:max-w-[50vw] md:max-w-[80vw] max-w-[90vh] ">
          <div className="flex justify-between gap-4 items-center flex-wrap py-3 px-5 lg:px-10 bg-primary text-white">
            <h4 className="lg:text-xl md:text-lg text-base font-bold">
              New Auction
            </h4>
          </div>

          <div className="p-5 lg:p-10">
            <AddNewAuction
              token_id={token_id}
              setToken_id={setToken_id}
              start_price={start_price}
              setStart_price={setStart_price}
              end_price={end_price}
              setEnd_price={setEnd_price}
              duration={duration}
              setDuration={setDuration}
              onSubmitHandler={onSubmitHandler}
              handleCancel={handleClose}
            />
          </div>
        </div>
      </Modal>

      {/* edit modal */}
      <Modal
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="bg-white rounded-lg w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[90vh] overflow-auto lg:max-w-[60vw] xl:max-w-[50vw] md:max-w-[80vw] max-w-[90vh] ">
          <div className="flex justify-between gap-4 items-center flex-wrap py-3 px-5 lg:px-10 bg-primary text-white">
            <h4 className="lg:text-xl md:text-lg text-base font-bold">
              Edit Auction
            </h4>
          </div>

          <div className="p-5 lg:p-10">
            <AddNewAuction
              token_id={token_id_edit}
              setToken_id={setAuctionId_edit}
              start_price={start_price_edit}
              setStart_price={setStart_price_edit}
              end_price={end_price_edit}
              setEnd_price={setEnd_price_edit}
              duration={duration_edit}
              setDuration={setDuration_edit}
              onSubmitHandler={(e) => onEditSubmitHandler(e)}
              handleCancel={handleEditClose}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
export const GetImage = ({ auction }) => {
  //console.log("Sulaiman auctionbauction", auction);
  const [nft_image, setNft_image] = useState(null);

  useEffect(() => {
    const getPanelId = async () => {
      try {
        // Fetch the image URL using your function that gets the URL based on the raffle
        const imageUrl = findUrlByNumber(auction[1]);

        // Construct the absolute URL for your API proxy
        const absoluteUrl = `https://gmgallery.blockchainbunch.com/api/image-proxy?imageUrl=${encodeURIComponent(
          imageUrl
        )}`;

        // Fetch the image using the absolute URL
        const res = await fetch(absoluteUrl);

        // Check if the response is OK
        if (!res.ok) {
          throw new Error("Something went wrong");
        }

        // Convert the response to a blob (image data)
        const imageBlob = await res.blob();

        // Create an object URL for the image from the blob
        const imageObjectUrl = URL.createObjectURL(imageBlob);

        // Set the image URL to state if the blob is valid
        if (imageBlob) {
          setNft_image(imageObjectUrl); // Use imageObjectUrl here to display the image correctly
        }
      } catch (err) {
        console.error(err); // Use console.error to log the error
      }
    };

    getPanelId();
  }, []);

  const [timeRemaining, setTimeRemaining] = useState();

  const starttime = auction?.startedAt;
  const duration = auction[4];

  useEffect(() => {
    if (starttime) {
      const currentTime = Date.now() / 1000;
      const elapsedTime = currentTime - starttime;
      const remainingTimeInSeconds = Math.max(duration - elapsedTime, 0);
      setTimeRemaining(remainingTimeInSeconds * 1000);
    }
  }, [starttime, duration]);

  //console.log("timeRemaining", timeRemaining);

  const startingPrice = (auction?.startingPrice / 1000000000000000000).toFixed(
    0
  );
  const endPrice = (auction?.endingPrice / 1000000000000000000).toFixed(0);

  return (
    <div className="flex gap-4 items-center">
      <img
        placeholder="blur"
        src={nft_image || "/noimage.png"}
        width={500}
        height={500}
        alt="test1"
        className="object-contain h-20 w-fit"
      />
      <div>
        <p className="text-sm font-semibold">NFT ID: {auction[1]}</p>
        <p className="text-sm">
          <PlayCircleOutlineIcon />{" "}
          <span className="font-bold text-primary">{startingPrice}</span>
        </p>
        <p className="text-sm">
          <StopCircleIcon />{" "}
          <span className="font-bold text-primary">{endPrice}</span>
        </p>
        {timeRemaining !== undefined && (
          <Countdown
            date={Date.now() + timeRemaining}
            className="text-sm font-bold text-primary"
          >
            <span className="text-sm text-red-700 font-bold">Time up</span>
          </Countdown>
        )}
      </div>
    </div>
  );
};
