import React, { useEffect, useState } from "react";
import { Button, IconButton, MenuItem } from "@mui/material";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Select from "@mui/material/Select";
import { useLocation } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useAccount } from "wagmi";
import { ConnectKitButton } from "connectkit";
import useContract from "../../hooks/useContract";
import GamesIcon from "@mui/icons-material/Games";
import StoreIcon from "@mui/icons-material/Store";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

export default function Header() {
  const { address } = useAccount();
  const [isOpen, setIsOpen] = useState(false);
  const [owner, setOwner] = React.useState("");
  const { getOwner } = useContract();

  useEffect(() => {
    const getOwnerAddress = async () => {
      const owner = await getOwner();
      setOwner(owner);
    };
    getOwnerAddress();
    //console.log(address);
  }, [address]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const location = useLocation();
  const { pathname } = location;

  const selectStyles = {
    borderRadius: "9999px", // This value makes the border fully rounded, giving a circular appearance
    color: "white", // Set the text color to black (or any other color you prefer)
  };
  return (
    <nav
      className={`${
        !address && pathname === "/" && pathname === "/"
          ? "bg-white/50"
          : "bg-primary/80"
      }`}
    >
      {/* desktop navbar */}
      <div className="container-sk py-2 lg:py-3 flex justify-between gap-8 items-center">
        <Link to="/">
          <img
            placeholder="blur"
            src={`${!address && pathname === "/" ? "logo.png" : "logo2.png"}`}
            width={500}
            height={500}
            alt="logo"
            className="cursor-pointer object-contain h-10 md:h-[40px] p-1 w-fit my-auto"
          
          />
        </Link>

        <div className="hidden md:flex justify-between items-center gap-6 tracking-wide lg:text-lg text-base">
          <ConnectKitButton />
          {!address && pathname === "/" && (
            <Button
              variant="contained"
              className={`${
                !address &&
                pathname === "/" &&
                "bg-primary text-white font-bold shadow-md"
              } hover:bg-primary text-white hover:shadow shadow-none capitalize  lg:text-base duration-300 rounded-full `}
            >
              Home
            </Button>
          )}
          {pathname !== "/" && !address && (
            <Link to="/">
              <Button
                variant="contained"
                className={`${
                  !address &&
                  pathname === "/" &&
                  "bg-primary text-white font-bold shadow-md"
                } hover:bg-primary text-white hover:shadow shadow-none capitalize  lg:text-base duration-300 rounded-full `}
              >
                Home
              </Button>
            </Link>
          )}
          {address && (
            <Link to="/">
              <Button
                variant="contained"
                className={`${
                  address && pathname === "/"
                    ? "bg-primary text-white font-bold shadow"
                    : "text-white "
                } hover:bg-primary hover:text-white hover:shadow  shadow-none capitalize lg:text-base duration-300 rounded-full `}
              >
                Staking
              </Button>
            </Link>
          )}

          <div class="dropdown inline-block relative">
            <button class="  py-2 px-4 rounded inline-flex items-center">
              <span class="mr-1 text-base text-white">Redemption</span>
              <svg
                class="fill-white h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </button>
            <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 bg-white shadow-md p-1 rounded-lg">
              {address && address === owner && (
                <Link to="/admin">
                  <li class="text-primary flex items-center gap-2 hover:bg-primary rounded-lg cursor-pointer hover:text-white py-2 px-4 text-base  ">
                    <AccountBoxIcon className="text-lg" /> Admin
                  </li>
                </Link>
              )}
              <Link to="/raffle">
                <li class="text-primary hover:bg-primary flex items-center gap-2 rounded-lg cursor-pointer hover:text-white py-2 px-4 text-base  ">
                  <StoreIcon className="text-lg" /> Raffle
                </li>
              </Link>
              <Link to="/auction">
                <li class="text-primary flex items-center gap-2 hover:bg-primary rounded-lg cursor-pointer hover:text-white py-2 px-4 text-base  ">
                  <AccountBalanceIcon className="text-lg" /> Auction
                </li>
              </Link>

              {/* <Link to="/games">
                <li class="text-primary flex items-center gap-2 hover:bg-primary rounded-lg cursor-pointer hover:text-white py-2 px-4 text-base  ">
                  <GamesIcon className="text-lg" /> Games
                </li>
              </Link> */}
            </ul>
          </div>
        </div>

        <IconButton
          onClick={toggleDrawer}
          className="bg-secondary/10 hover:bg-secondary/30 duration-300 md:hidden lg:hidden"
        >
          <MenuIcon className="text-black" />
        </IconButton>
      </div>

      {/* mobile navbar */}
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="text-black"
      >
        <div className="h-full bg-primary">
          <IconButton onClick={() => toggleDrawer()} className="m-3">
            <CloseIcon className="text-white" />
          </IconButton>
          <Link to="/">
            <img
              placeholder="blur"
              src="/logo2.png"
              width={500}
              height={500}
              alt="logo"
              className="object-contain h-[85px] w-fit p-5 my-auto mx-auto "
           
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </Link>

          <div className="flex justify-center items-center flex-col gap-4 tracking-wide lg:text-lg text-base mt-10">
            {!address && pathname === "/" && (
              <Button
                onClick={toggleDrawer}
                variant="contained"
                className={`${
                  pathname === "/"
                    ? "bg-primary shadow-md text-white shadow-white"
                    : "text-gray-700"
                } hover:bg-primary hover:text-white hover:shadow shadow-none capitalize lg:text-base duration-300 `}
              >
                Home
              </Button>
            )}

            {address && (
              <Link to="/">
                <Button
                  onClick={toggleDrawer}
                  variant="contained"
                  className={`${
                    address
                      ? "bg-primary shadow-md text-white shadow-white"
                      : "text-white"
                  } hover:bg-primary hover:text-white hover:shadow shadow-none capitalize lg:text-base duration-300 `}
                >
                  Staking
                </Button>
              </Link>
            )}
            <Link to="/raffle">
              <Button
                onClick={toggleDrawer}
                variant="contained"
                className={`${
                  address
                    ? "bg-primary shadow-md text-white shadow-white"
                    : "text-white"
                } hover:bg-primary  hover:text-white hover:shadow shadow-none capitalize lg:text-base duration-300 `}
              >
                Raffle
              </Button>
            </Link>
            <Link to="/auction">
              <Button
                onClick={toggleDrawer}
                variant="contained"
                className={`${
                  address
                    ? "bg-primary shadow-md text-white shadow-white"
                    : "text-white"
                } hover:bg-primary  hover:text-white hover:shadow shadow-none capitalize lg:text-base duration-300 `}
              >
                Auction
              </Button>
            </Link>

            {address && address === owner && (
              <Link to="/admin">
                <Button
                  onClick={toggleDrawer}
                  variant="contained"
                  className={`${
                    address
                      ? "bg-primary shadow-md text-white shadow-white"
                      : "text-white"
                  } hover:bg-primary  hover:text-white hover:shadow shadow-none capitalize lg:text-base duration-300 `}
                >
                  Admin
                </Button>
              </Link>
            )}

            {/* <Link to="/games">
              <Button
                onClick={toggleDrawer}
                variant="contained"
                className={`${
                  address
                    ? "bg-primary shadow-md text-white shadow-white"
                    : "text-white"
                } hover:bg-primary  hover:text-white hover:shadow shadow-none capitalize lg:text-base duration-300 `}
              >
                Games
              </Button>
            </Link> */}
          </div>
        </div>
      </Drawer>
    </nav>
  );
}
